.btn {
    color: white;
    text-align: center;
    padding: 0.5em;
    background: transparent;
    text-decoration: none;
    border-radius: 0.5em;
    font-size: 30px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    border: 2px solid white;
}

.btn:hover {
    background-color: #ea086e;
    transform: translateY(-4px);
    cursor: pointer; 
    transition: transform 0.2s;
}