
.card {
    font-family: Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}
.card video {
    width: 800px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.card video:hover {
    transform: translateY(-4px);
    cursor: pointer; 
    transition: transform 0.2s;
}

.card section {
    background-color: rgba(255, 255, 255, 0.107); 
    padding: 20px;
    border-radius: 25px;
    margin: 1em 1em 1em 1em;
    width: 400px;
    filter: drop-shadow(0px 4px 4px (#ea086e));
}

.card button {
    font-size: 10px;
    margin: 0.5em;
}

.card h3 {
    font-size: 25px;
}