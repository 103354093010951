.presentation {
    display: grid;
    grid-auto-flow: column;
    text-align: left;
    margin-left: 5%;
}

.presentation h1 {
    font-family: prompt;
    font-size: 45px;
    color: #FFF;
}

.presentation h4 {
    font-family: Roboto;
    font-size: 20px;
}

.presentation p {
    font-family: Roboto;
    font-size: 15px;
    text-align: justify;
    width: 750px;
}