.navbar {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

/*
.navbar img {
    display: flex;
    width: 300px;
    height: 40px;
    margin: 0.5em 0em 0em 0em;
    padding: 0%;
}

*/

.navbar ul {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 1em 0em 0em 0em;
    padding: 0%;
}

.navbar li {
    margin: 10px;
    font-family: Roboto;
    font-weight: 700;
}

.navbar li:hover {
    transform: translateY(-2px);
    transition: transform 0.2s;
    color: #ea086e;
}

.navbar a {
    color: white;
    text-decoration: none;
}

.navbar a:hover {
    color: #ea086e;
}