.btn {
    font-family: roboto;
    text-align: center;
    padding: 0.5em;
    background-color: white;
    text-decoration: none;
    border-radius: 0.5em;
    color: #000;
    font-size: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    border: none;
}

.btn:hover {
    color: #ea086e;
    transform: translateY(-4px);
    cursor: pointer; 
    transition: transform 0.2s;
}