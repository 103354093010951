.skill {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.312); 
    margin: 75px;
    padding: 15px;
    filter: drop-shadow(0px 4px 4px black);
}

.skill img {
    
    height: 140px;
    margin: 25px;
    filter: drop-shadow(0px 4px 4px #ea086e);

}

.skill h1 {
    font-family: Prompt;
    font-size: 45px;
}

.skill p {
    font-size: 20px;
}